import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kastle/kastle/packages/mdx/src/components/Layout/Default.tsx";
import { graphql } from 'gatsby';
import { FaHandshake, GiCastle, MdBook, MdMap, FaLaptop, FaPalette } from 'react-icons/all';
import Section from '@kastle/ui/src/atoms/Section/Section';
import { SectionType, SectionSize } from '@kastle/ui/src/atoms/Section/Section.types';
import FullImage from '@kastle/components/src/components/Image/FullImage';
import { CustomLayout, Head, ContentRow, ContentCol, Separator, SmallSpace } from '@kastle/ui/src/organisms/Layouts/LegacyTypicalLayout';
import Heading from '@kastle/ui/src/atoms/Heading/Heading';
import Text from '@kastle/ui/src/atoms/Text/Text';
import { TextFontSize } from '@kastle/ui/src/atoms/Text/Text.types';
import { Clients } from '../components/Slider/Clients';
import { Technologies } from '../components/Slider/Technologies';
import { FadeInAnimation } from '@kastle/ui/src/atoms/Animation/FadeIn';
import { FeatureSection } from '../sections/FeatureSection';
export const query = graphql`
  query {
    background: file(relativePath: { eq: "2020/11/background_1.jpg" }) {
      childImageSharp {
        gatsbyImageData(formats: WEBP, width: 500, layout: FULL_WIDTH)
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <Section type={SectionType.Secondary} size={SectionSize.MEDIUM} mdxType="Section">
  <FeatureSection className={FadeInAnimation.FOUR} scope="home.sections.presentation" mdxType="FeatureSection"></FeatureSection>
    </Section>
    <Section type={SectionType.Tertiary} size={SectionSize.MEDIUM} mdxType="Section">
  <CustomLayout mdxType="CustomLayout">
    <FeatureSection className={FadeInAnimation.FOUR} scope="home.sections.clients" mdxType="FeatureSection">
      <Clients mdxType="Clients" />
    </FeatureSection>
  </CustomLayout>
    </Section>
    <Section type={SectionType.Secondary} size={SectionSize.MEDIUM} mdxType="Section">
  <CustomLayout mdxType="CustomLayout">
    <FeatureSection className={FadeInAnimation.FOUR} scope="home.sections.technologies" mdxType="FeatureSection">
      <Technologies mdxType="Technologies" />
    </FeatureSection>
  </CustomLayout>
    </Section>
    <Section type={SectionType.Tertiary} size={SectionSize.MEDIUM} mdxType="Section">
  <CustomLayout mdxType="CustomLayout">
    <Head mdxType="Head">
      <FeatureSection className={FadeInAnimation.FOUR} scope="home.sections.design" mdxType="FeatureSection" />
    </Head>
  </CustomLayout>
    </Section>
    <Section type={SectionType.Secondary} size={SectionSize.MEDIUM} mdxType="Section">
  <CustomLayout mdxType="CustomLayout">
    <FeatureSection className={FadeInAnimation.FOUR} scope="home.sections.indexing" mdxType="FeatureSection" />
  </CustomLayout>
    </Section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      